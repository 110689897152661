import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dialog, Transition } from '@headlessui/react';

const apiUrl = process.env.REACT_APP_API_URL;
const pageSize = 30;

function Resumen() {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [perfiles, setPerfiles] = useState([]);
const [zonas, setZonas] = useState([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({ 
            rut: '',
            nombres: '',
            apellidos: '',
            cargo: '',
            celular: '',
            direccion: '',
            comuna: '',
            region: '', 
            perfil: '',
            zona: '',
            email: ''
       
    });

    const fetchData = async () => {
        try {
            const responsezc = await axios.get(`${apiUrl}/user/users-with-accounts`);
            console.log(responsezc);
            setUsers(responsezc.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        // Función para obtener perfiles y zonas
        const fetchData = async () => {
            try { 
                const response = await axios.get(`${apiUrl}/perfil/`);
                setPerfiles(response.data); 
    
                const responsez = await axios.get(`${apiUrl}/zonas/`);
                setZonas(responsez.data);  
                const responsezb = await axios.get(`${apiUrl}/user/users-with-accounts`);
                setUsers(responsezb.data);
            } catch (error) {
                console.error('Error fetching perfiles and zonas:', error);
            }
        };
    
        fetchData();
    }, []);

    // Filtrar los datos basados en el término de búsqueda
    const filteredData = users.filter((user) => {
      
        const fullName = user.nombres.toLowerCase();
        const lastName = user.apellidos.toLowerCase();
        const rutS=user.rut;
        return fullName.includes(searchTerm.toLowerCase()) || lastName.includes(searchTerm.toLowerCase())|| rutS.includes(searchTerm.toLowerCase());
    });

    // Paginación
    const startIndex = (currentPage - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredData.length / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleActivate = (user) => {
        axios.post(`${apiUrl}/user/activate`, user).then(response => {
            fetchData();
        }).catch(error => {
            console.error('Error activando el usuario:', error);
        });
    };

    const handleDeactivate = (user) => {
        axios.post(`${apiUrl}/user/deactivate`, user).then(response => {
            fetchData();
        }).catch(error => {
            console.error('Error desactivando el usuario:', error);
        });
    };

    const openModal = (user) => {
        setSelectedUser(user);
        setFormData(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
        setFormData({
            rut: '',
            nombres: '',
            apellidos: '',
            cargo: '',
            celular: '',
            direccion: '',
            comuna: '',
            region: '', 
            perfil: '',
            zona: '',
            email: ''
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
 // Nueva función para resetear la clave
 const handleResetPassword = (user) => {
    Swal.fire({
        title: '¿Estás seguro?',
        text: "Se reseteará la clave del usuario.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí, resetear'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post(`${apiUrl}/user/resetpassword`, { rut: user.rut })
                .then((response) => {
                    Swal.fire(
                        '¡Hecho!',
                        'La clave ha sido reseteada, la contraseña es: '+response.data,
                        'success'
                    );
                })
                .catch((error) => {
                    Swal.fire(
                        'Error',
                        'Hubo un problema al resetear la clave.',
                        'error'
                    );
                    console.error('Error resetting password:', error);
                });
        }
    });
};

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`${apiUrl}/user/editar/${selectedUser._id}`, formData)
            .then(response => {
                fetchData();
                closeModal();
            })
            .catch(error => {
                console.error('Error updating user:', error);
            });
    };

    const start = startIndex + 1;
    const end = Math.min(startIndex + pageSize, filteredData.length);

    return (
        <div className="container mx-auto p-4">
            <div className="mb-4 flex flex-row items-center">
                <div className="w-1/2 ml-2">
                    <label htmlFor="search" className="block text-sm font-medium text-gray-700">Buscar:</label>
                    <input
                        type="text"
                        id="search"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                        placeholder="Nombre, apellido o rut del usuario"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1);
                        }}
                    />
                </div>
                <div className="mt-1 w-full pl-3 text-base flex"><p> </p><br></br><a className="text-gray-600  hover:text-gray-900 group flex items-end px-2 py-4 text-sm font-medium rounded-md" href="https://litrosdefelicidad.cl:3800/user/export/excel"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"><path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"></path></svg>Descargar Usuarios</a></div>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <div>
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rut</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombres</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apellidos</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cargo</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zona</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros Subidos</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros Gastados</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros Totales</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {paginatedData.map((user) => (
                            <tr key={user._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.rut}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.nombres}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.apellidos}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.cargo}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.zona}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.account[0]?.totalIngresos || 0}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.account[0]?.totalEgresos || 0}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.account[0]?.balance || 0}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <button
                                        onClick={() => handleResetPassword(user)}
                                        className="bg-purple-500 text-white px-3 py-1 rounded mr-2"
                                    >
                                        Resetear Clave
                                    </button>
                                    <Link to={`/detalles/${user._id}`} state={{ state: user }} key={user._id} className='bg-blue-500 text-white px-3 py-1 rounded mr-2'>
                                        Ver Detalles
                                    </Link>
                                    <button
                                        onClick={() => openModal(user)}
                                        className="bg-green-500 text-white px-3 py-1 rounded mr-2"
                                    >
                                        Editar
                                    </button>
                                    {user.isActive ? (
                                        <button
                                            onClick={() => handleDeactivate(user)}
                                            className="bg-red-500 text-white px-3 py-1 rounded"
                                        >
                                            Desactivar
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => handleActivate(user)}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded"
                                        >
                                            Activar
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between mt-4">
                <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
                >
                    Anterior
                </button>
                <p className="text-sm text-gray-700">
                    Mostrando {start} a {end} de {filteredData.length} resultados
                </p>
                <button
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(filteredData.length / pageSize)}
                    className={`px-4 py-2 rounded ${currentPage === Math.ceil(filteredData.length / pageSize) ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
                >
                    Siguiente
                </button>
            </div> 
<Transition appear show={isModalOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Editar Usuario
                        </Dialog.Title>
                        <form onSubmit={handleSubmit} className="mt-4">
                            {/* RUT */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">RUT</label>
                                <input
                                    type="text"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    required
                                />
                            </div>
                            {/* Nombres */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Nombres</label>
                                <input
                                    type="text"
                                    name="nombres"
                                    value={formData.nombres}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    required
                                />
                            </div>
                            {/* Apellidos */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Apellidos</label>
                                <input
                                    type="text"
                                    name="apellidos"
                                    value={formData.apellidos}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    required
                                />
                            </div>
                            {/* Cargo */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Cargo</label>
                                <input
                                    type="text"
                                    name="cargo"
                                    value={formData.cargo}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            {/* Celular */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Celular</label>
                                <input
                                    type="text"
                                    name="celular"
                                    value={formData.celular}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            {/* Dirección */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Dirección</label>
                                <input
                                    type="text"
                                    name="direccion"
                                    value={formData.direccion}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            {/* Comuna */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Comuna</label>
                                <input
                                    type="text"
                                    name="comuna"
                                    value={formData.comuna}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            {/* Región */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Región</label>
                                <input
                                    type="text"
                                    name="region"
                                    value={formData.region}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                          
                            {/* Perfil */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Perfil</label>
                                <select
                                    name="perfil"
                                    value={formData.perfil}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                >
                                    <option value="">Seleccione un perfil</option>
                                    {perfiles.map((perfil) => (
                                        <option key={perfil._id} value={perfil._id}>
                                            {perfil.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Zona */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Zona</label>
                                <select
                                    name="zona"
                                    value={formData.zona}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                >
                                    <option value="">Seleccione una zona</option>
                                    {zonas.map((zona) => (
                                        <option key={zona._id} value={zona._id}>
                                            {zona.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Email */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border  border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                     
                                />
                            </div>
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                >
                                    Guardar
                                </button>
                                <button
                                    type="button"
                                    className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                                    onClick={closeModal}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </div>
    </Dialog>
</Transition>
        </div>
    );
}

export default Resumen;