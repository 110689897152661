import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const apiUrl = process.env.REACT_APP_API_URL;

function VerLitrosSubidos() {
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [ventas, setVentas] = useState(null);
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadId, setUploadId] = useState('');
    const handleFilter = async () => {
        const fechaHasta = new Date();
        const fechaDesde = new Date();
        fechaDesde.setDate(fechaDesde.getDate() - 365);
    
        const formatFecha = (fecha) => {
            const year = fecha.getFullYear();
            const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0 a 11
            const day = String(fecha.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedFechaHasta = formatFecha(fechaHasta);
        const formattedFechaDesde = formatFecha(fechaDesde);
        try {
            
            const response = await axios.get(`${apiUrl}/points/history`, {
                params: {
                    startDate: formattedFechaDesde,
                    endDate: formattedFechaHasta,
                    type: 'ingreso'
                }
            });
            setVentas(response.data || []); 
        } catch (error) {
            console.error('Error fetching data:', error);
            setVentas([]); // En caso de error, establece un array vacío
        }
    };
   
    const handleSelect = (id) => {
        setSelectedIds((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleDeleteSelected = async () => {
        if (selectedIds.size === 0) {
            alert('No hay transacciones seleccionadas para eliminar');
            return;
        }

        const confirmDelete = window.confirm('¿Está seguro de que desea eliminar las transacciones seleccionadas?');
        if (!confirmDelete) {
            return;
        }

        try {
            for (const transactionId of selectedIds) {
                const venta = ventas.find(v => v.id === transactionId);
                if (venta && venta.user && venta.user._id) {
                    await axios.post(`${apiUrl}/points/delete-transaction`, {
                        userId: venta.user._id,
                        transactionId: transactionId
                    });
                }
            }
            alert('Transacciones eliminadas con éxito');
            handleFilter(); // Volver a cargar los datos después de eliminar
            setSelectedIds(new Set()); // Resetear las selecciones
        } catch (error) {
            console.error('Error al eliminar transacciones:', error);
            alert('Error al eliminar transacciones');
        }
    };

    const handleDeleteLast = () => {
        setIsModalOpen(true); // Abrir el modal
    };
    
    const handleConfirmDelete = async () => {
        if (!uploadId) {
            alert('Debe ingresar un identificador de subida');
            return;
        }
    
        try {
            await axios.post(`${apiUrl}/points/history/deleteIncomeHistories`, { ingreso: uploadId });
            alert('Última subida eliminada con éxito');
            setIsModalOpen(false); // Cerrar el modal
            handleFilter(); // Actualizar la lista
        } catch (error) {
            console.error('Error al eliminar la subida:', error);
            alert('Error al eliminar la subida');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const fechaHasta = new Date();
            const fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 365);
        
            const formatFecha = (fecha) => {
                const year = fecha.getFullYear();
                const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0 a 11
                const day = String(fecha.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };
        
            const formattedFechaHasta = formatFecha(fechaHasta);
            const formattedFechaDesde = formatFecha(fechaDesde);
        
            try {
                const response = await axios.get(`${apiUrl}/points/history`, {
                    params: {
                        startDate: formattedFechaDesde,
                        endDate: formattedFechaHasta,
                        type: 'ingreso'
                    }
                });
                setVentas(response.data || []); 
            } catch (error) {
                console.error('Error fetching data:', error);
                setVentas([]); // En caso de error, establece un array vacío
            }
        };
        fetchData();
    }, []);

    const exportToExcel = () => {
        if (ventas.length === 0) {
            alert('No hay nada para exportar');
        } else { 
            const flattenedData = ventas.map(venta => ({
                'Valor': venta.amount,
                'Marca': venta?.marca?.nombre || '',
                'Concurso': venta.concurso,
                'Rut': `${venta.user.rut}`,
                'Usuario': `${venta.user.nombres} ${venta.user.apellidos}`,
                'Mes Concurso': venta.mes,
                'Fecha de actualización': venta.date,
                'Identificador de subida': venta.ingreso
            }));
            const worksheet = XLSX.utils.json_to_sheet(flattenedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Litros Subidos");
            XLSX.writeFile(workbook, 'litros_subidos.xlsx');
        }
    };

    const renderResults = (ventas) => {
        if (!ventas) {
            return <p></p>;
        } else if (ventas.length === 0) {
            return <p>No hay registros para mostrar</p>;
        } else {
            return (
                <table className="min-w-full divide-y divide-gray-200 mb-4">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Seleccionar</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Valor</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Marca</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Concurso</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rut Usuario</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre Usuario</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mes Concurso</th> 
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de actualización</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Identificador de subida</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {ventas.sort((a, b) => new Date(b.date) - new Date(a.date)).map((venta) => (
                            <tr key={venta.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <input type="checkbox" onChange={() => handleSelect(venta.id)} />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.amount || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta?.marca?.nombre || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.concurso || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.user ? `${venta.user.rut} ` : ''}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.user ? `${venta.user.nombres} ${venta.user.apellidos}` : ''}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.mes || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.date ? new Date(venta.date).toLocaleDateString() : ''}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.ingreso || ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Historial de Ventas subidas</h1>
            <div className="flex space-x-4 mb-4">
                <div>
                    <label htmlFor="fechaDesde" className="block text-sm font-medium text-gray-700">Desde</label>
                    <input type="date" id="fechaDesde" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="fechaHasta" className="block text-sm font-medium text-gray-700">Hasta</label>
                    <input type="date" id="fechaHasta" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <button onClick={handleFilter} className="self-end bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">Filtrar</button>
            </div>
            <div className="flex space-x-4">
            <button onClick={handleDeleteSelected} className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600">Eliminar Seleccionados</button>
                <button onClick={handleDeleteLast} className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600">Eliminar subida</button>
                <button onClick={exportToExcel} className="bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600">Exportar a Excel</button>
            </div>
            <div className="overflow-x-auto mt-2">
                {renderResults(ventas)}
            </div>
            {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-bold mb-4">Eliminar Subida</h2>
                    <label htmlFor="uploadId" className="block text-sm font-medium text-gray-700">
                        Ingrese el Identificador de Subida
                    </label>
                    <input
                        type="text"
                        id="uploadId"
                        value={uploadId}
                        onChange={(e) => setUploadId(e.target.value)}
                        className="mt-2 mb-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="bg-gray-500 text-white px-3 py-2 rounded hover:bg-gray-600"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleConfirmDelete}
                            className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        )}
        </div>
       
    );
}

export default VerLitrosSubidos;